import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  key: 1,
  style: {
    "font-size": "10rem"
  }
};
import HeadComponent from "@/components/HeadComponent.vue";
import MobileHeader from "@/components/MobileHeader.vue";
import MobileFooter from "@/components/MobileFooter.vue";
import MobileSlider from "@/components/MobileSlider.vue";
import { isMobileFlag } from "@/config";
import { ref, watch } from "vue";
import { useMobileHeaderStore, useSliderComponentStore } from "@/store";
import { useRoute } from "vue-router";
export default {
  __name: 'App',
  setup(__props) {
    const route = useRoute();
    const show = ref(true);
    const footerShow = ref(false);
    const scStore = useSliderComponentStore();

    // 监听slider状态，控制滑动
    watch(() => scStore.show, value => {
      if (value) {
        document.body.style.position = "fixed";
      } else {
        document.body.style.position = "relative";
      }
    });
    watch(() => route.meta.headHidde, value => {
      if (value) {
        show.value = false;
      } else {
        show.value = true;
      }
    }, {
      immediate: true
    });
    watch(() => route.meta.footerHidde, value => {
      if (value) {
        footerShow.value = false;
      } else {
        footerShow.value = true;
      }
    }, {
      immediate: true
    });
    const mhStore = useMobileHeaderStore();
    const isMobile = ref(isMobileFlag);
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return isMobile.value ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "container",
        style: _normalizeStyle({
          paddingTop: _unref(mhStore).height + 'px'
        })
      }, [show.value ? (_openBlock(), _createBlock(MobileHeader, {
        key: 0
      })) : _createCommentVNode("", true), _createVNode(MobileSlider), _createVNode(_component_router_view), footerShow.value ? (_openBlock(), _createBlock(MobileFooter, {
        key: 1
      })) : _createCommentVNode("", true)], 4)) : (_openBlock(), _createElementBlock("div", _hoisted_1, [show.value ? (_openBlock(), _createBlock(HeadComponent, {
        key: 0
      })) : _createCommentVNode("", true), (_openBlock(), _createBlock(_component_router_view, {
        key: _unref(route).fullPath
      }))]));
    };
  }
};