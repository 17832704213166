// function isMobile() {
//   let flag = window.navigator.userAgent.match(
//     /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
//   );
//   return flag;
// }

// export const isMobileFlag = isMobile() ? true : false;
export const isMobileFlag = false;
export const baseURL = "https://aimingmed.com/prod-api/";
// export const baseURL = "http://192.168.3.83:19911";
// export const baseURL = "http://192.168.3.80:19911";

//
// http://mnaiming.shenniudata.com/  后台
//   http://aiming.shenniudata.com/  商城
//     http://enaiming.shenniudata.com/  英文版
